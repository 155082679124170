import { useMsal } from "@azure/msal-react";
import { CircularProgress, Flex, Text } from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { ProductFruits } from "react-product-fruits";

import { InitLoader } from "@/components/loaders/InitLoader";
import {
  useCreateOrguserMutation,
  useLazyGetTenantIdQuery,
  useLazyGetUserIdQuery,
} from "@/features/user-manager";
import { ErrorComponent } from "@/features/user-manager/components/UserNotFound";
import { IUserMetadata } from "@/features/user-manager/types";
import { useAppDispatch } from "@/reduxHooks";
import { setAccountUserId, setCurrentUserMetadata } from "@/slices/auth-slice";
import { CacheKey, cacheUtil } from "@/utils/localstorage";

const errorMessage = {
  "404":
    "You are not a member of this Organization.\nReach out to the Organization admins for more information.",
};

const AppInitializer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [getTenant] = useLazyGetTenantIdQuery();
  const [getUserId] = useLazyGetUserIdQuery();
  const [createOrgUser] = useCreateOrguserMutation();
  const { instance } = useMsal();
  const dispatch = useAppDispatch();
  const activeAccount = instance.getActiveAccount();
  const [userInfo, setUserInfo] = useState<any>();

  useEffect(() => {
    const checkTenant = async () => {
      try {
        const storedToken = cacheUtil.getUnParsed(CacheKey.TenantId);
        if (!isEmpty(storedToken)) return storedToken;
        else {
          const response = await getTenant().unwrap();
          const newTenantId = response?.response?.data?.tenantId;
          if (newTenantId) {
            cacheUtil.set(CacheKey.TenantId, newTenantId);
            return newTenantId;
          }
        }
        return false;
      } catch (err) {
        console.log(err);
      }
    };

    const checkUserId = async () => {
      try {
        const jwtToken = activeAccount?.idToken as string;
        const oid = jwtDecode<{ oid: string }>(jwtToken).oid;

        const response = await getUserId({ oid }).unwrap();
        const newUserId = response?.response?.data?.userId as string;
        const userMetadata = response?.response?.data as IUserMetadata;
        if (newUserId) {
          cacheUtil.set(CacheKey.UserId, newUserId);
          dispatch(setCurrentUserMetadata(userMetadata));
          dispatch(setAccountUserId(newUserId));
          return { userId: newUserId, userRole: userMetadata.role };
        }
        // }
        return { userId: undefined, userRole: undefined };
      } catch (err: any) {
        if (err.status === 404) {
          setError(new Error(errorMessage["404"]));
        } else {
          setError(
            err instanceof Error
              ? err
              : new Error("Failed to fetch Credentials. Please reload the app")
          );
        }
      }
    };

    const tempUserSignUp = async (tenantId: string) => {
      try {
        const jwtToken = activeAccount?.idToken as string;
        const jwt: any = jwtDecode(jwtToken);
        const oid = jwt.oid;
        const first_name = jwt.given_name || "";
        const last_name = jwt.family_name || "";
        const email = jwt.emails[0] || "";
        const body = {
          "X-TENANT-ID": tenantId,
          first_name: first_name,
          last_name: last_name,
          email: email,
          oid: oid,
        };
        await createOrgUser({ data: body }).unwrap();
        return true;
      } catch (e) {
        console.error("Error - User was created without signing up.", e);
        return false;
      }
    };

    const initializeApp = async () => {
      try {
        const tenantId = await checkTenant();
        if (!tenantId) {
          setError(new Error("Failed to initialize app"));
          return;
        }

        await tempUserSignUp(tenantId);

        const user = await checkUserId();
        if (!isEmpty(user) || !isEmpty(tenantId)) {
          setUserInfo({
            username: user?.userId,
            role: user?.userRole,
          });
          setIsInitialized(true);
        }
      } catch (err) {
        setError(
          err instanceof Error
            ? err
            : new Error("Failed to fetch Credentials. Please reload the app")
        );
      }
    };

    initializeApp();
  }, []);

  if (error) {
    return <ErrorComponent error={error.message} />;
  }

  // Or a more sophisticated loading component
  if (!isInitialized) {
    return <InitLoader />;
  }

  return (
    <div>
      <ProductFruits
        workspaceCode="L0Csps2QI2SbYUFE"
        language="en"
        user={userInfo}
      />
      {children}
    </div>
  );
};

export default AppInitializer;
