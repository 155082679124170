import { Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

export const SettingsLayout = () => {
  return (
    <Flex className="flex-col overflow-hidden w-screen max-h-screen h-screen">
      <Outlet />
    </Flex>
  );
};
