import { Box, Button, Flex } from "@chakra-ui/react";

import { ToastType, useShowToast } from "@/components/toast";
import { useAppSelector } from "@/reduxHooks";
import { USER_ROLES } from "@/utils/enums";
import { CacheKey, cacheUtil } from "@/utils/localstorage";

import {
  useLazyGetOrgsForUserQuery,
  useRemoveOrgUserMutation,
} from "../../api";

const LeaveOrgBody = ({ onClose }: { onClose: () => void }) => {
  const { modalProps } = useAppSelector((state) => state.rootReducer.modals);
  const toast = useShowToast(undefined, undefined, true);

  const [refetchOrgs] = useLazyGetOrgsForUserQuery();
  const [removeOrgUserApi, { isLoading: isRemoving }] =
    useRemoveOrgUserMutation();

  const leaveOrg = async () => {
    const userId = modalProps.user.userId;
    const tenantName = modalProps.org.tenantName;
    const currentTenant = cacheUtil.getUnParsed(CacheKey.TenantId);
    try {
      await removeOrgUserApi({ userId, tenantName }).unwrap();
      onClose();
      toast({
        title: "Left organization successfully",
        status: ToastType.Success,
      });
      if (currentTenant === tenantName) {
        setTimeout(() => {
          cacheUtil.remove(CacheKey.UserId);
          window.location.reload();
        }, 100);
      } else {
        refetchOrgs({ email: modalProps.user.email })
          .unwrap()
          .catch((e) => console.log(e));
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  if (modalProps.org.orgRole === USER_ROLES.ADMIN) {
    return (
      <Flex className="flex-col gap-3">
        <Box>
          You are the admin of this organization. Please remove yourself as an
          admin before leaving.
        </Box>
        <Flex className="!flex !justify-end">
          <Button colorScheme={"dark"} onClick={onClose} variant={"solid"}>
            Okay
          </Button>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex className="flex-col gap-3">
      <Box>
        Are you sure you want to leave this&nbsp;
        {modalProps.org.orgName ?? "this org"} ?
      </Box>
      <Box>You will not be able to access this organization anymore.</Box>
      <Flex className="!flex !justify-between">
        <Button
          colorScheme="secondary"
          isDisabled={isRemoving}
          onClick={onClose}
          variant="ghost"
        >
          Cancel
        </Button>
        <Button
          colorScheme={"customRed"}
          isDisabled={isRemoving}
          isLoading={isRemoving}
          onClick={leaveOrg}
          variant={"solid"}
        >
          Leave Organization
        </Button>
      </Flex>
    </Flex>
  );
};

export default LeaveOrgBody;
