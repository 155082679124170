import { Box, Flex, ModalCloseButton, Text } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";

import { TOAST_DURATION, ToastType, useShowToast } from "@/components/toast";
import { Button } from "@/design/components/button";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import {
  useLazyGetUsersListQuery,
  useRemoveOrgAdminMutation,
} from "@/features/user-manager";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { currentUserMetadata } from "@/slices/auth-slice";
import { closeModal } from "@/slices/modal-slice.ts";
import { RootState } from "@/store";
import { PaginationBody } from "@/types";

const RemoveConfirmation: React.FC = () => {
  const { modalProps } = useSelector(
    (state) => (state as RootState).rootReducer.modals
  );
  const toast = useShowToast(undefined, undefined, true);
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserMetadata);
  const [fetchUsers] = useLazyGetUsersListQuery();

  const [removeAdmin, { isLoading: isRemoving }] = useRemoveOrgAdminMutation();

  const removeOrgAdmin = async () => {
    const userId = modalProps.user.id;
    const isselfUser = userId === currentUser?.userId;
    try {
      onClose();
      toast({
        id: "removeAdmin" + userId,
        title: "Removing Admin access",
        status: ToastType.Loading,
        duration: null,
      });
      await removeAdmin({ userId }).unwrap();
      toast.update("removeAdmin" + userId, {
        title: "Admin access Removed",
        status: ToastType.Success,
        duration: TOAST_DURATION.SHORT,
      });
      await fetchUsers({} as PaginationBody).unwrap();
      if (isselfUser) {
        window.location.reload();
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal isOpen={true} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className="!py-4">
        <ModalHeader>Remove Admin</ModalHeader>
        <ModalCloseButton className="!mr-0" />
        <ModalBody className="!px-6">
          <Box>
            Demoting an Organization Admin to a member role will remove their
            existing access from all workspaces, projects and analyses in this
            organization.
            <br />
            <br />
            Are you sure you wish to remove this Admin and reset their access?
          </Box>
          <Flex className="mt-6 !flex !justify-between">
            <Button
              onClick={onClose}
              variant="ghost"
              isDisabled={isRemoving}
              colorScheme="secondary"
              size={"sm"}
            >
              Cancel
            </Button>
            <Button
              variant={modalProps.primaryButtonVariant ?? "solid"}
              colorScheme={modalProps.primaryButtonColorScheme ?? "customRed"}
              isLoading={isRemoving}
              isDisabled={isRemoving}
              onClick={removeOrgAdmin}
              size={"sm"}
            >
              Remove Admin & Reset Access
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RemoveConfirmation;
