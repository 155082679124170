interface ModalMessage {
  title: string;
  description: string;
  subDescription?: string; // Optional property
}
export const MODAL_MESSAGES: { [key: string]: ModalMessage } = {
  deleteProject: {
    title: "Delete Project?",
    description:
      "Are you sure you want to delete this project? Any ongoing runs will be terminated and all users will lose access to the project.",
    subDescription: "This action cannot be undone.",
  },
  deleteAnalysis: {
    title: "Delete Analysis?",
    description:
      "Are you sure you want to delete this analysis? Any ongoing runs will be terminated and all users will lose access to the analysis.",
    subDescription: "This action cannot be undone.",
  },
  deleteWorkspace: {
    title: "Delete Workspace?",
    description:
      "Are you sure you want to delete this workspace? Any ongoing runs will be terminated and all users will lose access to the workspace.",
    subDescription: "This action cannot be undone.",
  },
  restoreProject: {
    title: "Restore Project?",
    description:
      "This will restore all Analyses and Dashboards. Access will also be restored for all the users that had access to the Project at the time of deletion.",
    subDescription: "Are you sure that you would like to proceed?",
  },
  restoreAnalysis: {
    title: "Restore Analysis?",
    description:
      "Restoring the Analysis will also restore all the associated Dashboards. Access will also be restored for all the Users that had access to the Analysis at the time of deletion.",
    subDescription: "Are you sure that you would like to proceed?",
  },
  restoreWorkspace: {
    title: "Restore Workspace?",
    description:
      "This will restore all Projects, Analyses and Dashboards. Access will also be restored for all the users that had access to the Workspace at the time of deletion.",
    subDescription: "Are you sure that you would like to proceed?",
  },
};
