import { AxiosError } from "axios";
import { camelCase } from "lodash";
import React, { createElement } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ToastType, useShowToast } from "@/components/toast";
import { useAppDispatch } from "@/reduxHooks.ts";
import { closeModal, ModalTypes, openModal } from "@/slices/modal-slice.ts";
import {
  DATA_UPLOAD_STATUS,
  VALIDATION_STATUS,
  VALIDATION_TYPES,
} from "@/utils/enums.ts";
import { getFileExtension, getFileName } from "@/utils/string-utils.ts";

import {
  useFinishUploadMutation,
  useRunValidationsMutation,
  useUploadChunkMutation,
  useUploadLocalFileMutation,
} from "../api";
import { addOnGoingUploads, updateOnGoingUploads } from "../redux";
import { IDataSource, IFileExtraOptions, IoRecord } from "../types";

const fileSizeLimit = 200 * 1024 * 1024; // 200MB limit

export function useFileUpload({
  progressComponent,
}: {
  progressComponent?: (progress?: number) => React.ReactNode;
}) {
  const dispatch = useAppDispatch();
  const toast = useShowToast(undefined, undefined, true);
  const navigate = useNavigate();
  const params = useParams();
  const [uploadChunkApi] = useUploadChunkMutation();
  const [finishUploadApi] = useFinishUploadMutation();
  const [uploadFile] = useUploadLocalFileMutation();
  const [validateName] = useRunValidationsMutation();

  const validateFileFormat = (fileName: string, source: IDataSource) => {
    const fileExtension = getFileExtension(fileName);
    const supportedFormats = source.allowedFormats.map((af) => af.formatStr);
    if (!supportedFormats.includes(fileExtension)) {
      toast({
        title: "Error : File format not supported",
        status: "error",
      });
      return false;
    }
    return true;
  };

  const validateTitle = async (fileName: string) => {
    const validationType = VALIDATION_TYPES.DUPLICATE_TITLE;
    const res = await validateName({
      analysisId: params.analysisId!,
      validationType,
      body: { dataset_title: fileName },
    }).unwrap();

    const response = res.response.data?.results[0][camelCase(validationType)];
    if (response?.validationStatus === VALIDATION_STATUS.FAILED) {
      toast({
        title: "Error: Upload failed",
        description: response?.validationMessage,
        status: ToastType.Error,
      });
      return { isValid: false, message: response?.validationMessage };
    }
    return { isValid: true, message: "success" };
  };

  const getIoRecord = async (
    selectedFile: File,
    fileName: string,
    source: IDataSource,
    options: IFileExtraOptions = {}
  ) => {
    const uploadFormData = new FormData();
    uploadFormData.append("actor_definition_id", source.id);
    uploadFormData.append("name", fileName);
    uploadFormData.append(
      "data_format_str",
      getFileExtension(selectedFile.name)
    );
    if (options.sheetName) {
      uploadFormData.append("sheet_name", options.sheetName);
    }

    const resource = await uploadFile({
      formData: uploadFormData,
      analysisId: params.analysisId!,
    }).unwrap();

    const ioRecord = resource?.response?.data?.results[0] as IoRecord;
    if (!ioRecord) {
      throw new Error("Error: Upload failed");
    }
    return ioRecord;
  };

  const validateFile = async (selectedFile: File, source: IDataSource) => {
    const isValidFileFormat = validateFileFormat(selectedFile.name, source);
    const isValidName = await validateTitle(getFileName(selectedFile.name));
    return isValidFileFormat && isValidName;
  };

  const handleUploadChunks = async ({
    url = undefined,
    chunkIndex = 0,
    ioRecord,
    selectedFile,
    options = {},
    areAllChunksUploaded = false,
    fileName,
    ioUploadId,
  }: {
    startByte: number | undefined;
    endByte: number | undefined;
    url: string | undefined;
    chunkIndex: number | undefined;
    totalSize: number | undefined;
    ioRecord: IoRecord | undefined;
    selectedFile: File;
    areAllChunksUploaded?: boolean;
    options?: IFileExtraOptions;
    fileName?: string;
    ioUploadId?: string;
  }) => {
    if (!selectedFile || !ioRecord) return;

    const chunkSize = 8 * 1024 * 1024;
    const totalChunks = Math.ceil(selectedFile.size / chunkSize);
    let uploadUrl = url ?? undefined;
    let uploadId = ioUploadId ?? undefined;

    let updatedIoRecord = ioRecord;

    const startChunkNumber = chunkIndex || 0;
    console.log(areAllChunksUploaded);
    if (!areAllChunksUploaded) {
      for (
        let chunkNumber = startChunkNumber;
        chunkNumber < totalChunks;
        chunkNumber++
      ) {
        const startByte = chunkNumber * chunkSize;
        const endByte = Math.min(startByte + chunkSize, selectedFile.size);
        const chunk = selectedFile.slice(startByte, endByte);
        const totalSize = selectedFile.size;

        const formData = new FormData();
        formData.append("analysis_id", params.analysisId!);
        formData.append("io_record_id", ioRecord.id);
        formData.append("filename", fileName ?? selectedFile.name);
        formData.append("file", chunk);

        updatedIoRecord = {
          ...ioRecord,
          hasUploadStarted: true,
          state: DATA_UPLOAD_STATUS.UPLOADING_TO_CS_ZONE,
          statusMsg: "Uploading",
          uploadMetaData: {
            lastChunkEndByte: endByte,
            lastChunkStartByte: startByte,
            lastChunkIndex: chunkNumber,
            uploadPercentage: Math.round(
              ((chunkNumber + 1) / totalChunks) * 100
            ),
            uploadUrl: uploadUrl,
            file: selectedFile,
            totalSize: selectedFile.size,
            fileName,
          },
        };

        dispatch(
          updateOnGoingUploads({
            analysisId: params.analysisId!,
            id: ioRecord.id,
            ioRecord: updatedIoRecord,
          })
        );

        const response = await uploadChunkApi({
          url: uploadUrl,
          formData,
          startByte,
          endByte,
          totalSize,
        })
          .unwrap()
          .catch((error) => {
            console.log(error);
            let errMsg;
            if (error?.status === 500) {
              errMsg = "Internal Server Error";
            }
            const addErrorToUpload: Partial<IoRecord> = {
              statusMsg:
                errMsg ?? "Network Connection Interrupted or File is missing",
              state: DATA_UPLOAD_STATUS.FAIL,
            };
            dispatch(
              updateOnGoingUploads({
                analysisId: params.analysisId!,
                id: ioRecord.id,
                ioRecord: addErrorToUpload,
              })
            );
          });

        uploadUrl = response?.url;
        uploadUrl = uploadUrl!.replace("http://127.0.0.1:8000", "");
        uploadId = response?.id;
      }
      console.log(uploadUrl);
      dispatch(
        updateOnGoingUploads({
          analysisId: params.analysisId!,
          id: ioRecord.id,
          ioRecord: {
            ...updatedIoRecord,
            uploadMetaData: {
              ...updatedIoRecord.uploadMetaData,
              uploadUrl: uploadUrl,
              uploadId: uploadId,
              allChunksUploaded: true,
            },
          },
        })
      );
    }
    await finishUploadApi({
      url: uploadUrl ?? "",
      analysisId: params.analysisId!,
      ioRecordId: ioRecord.id,
      sheetName: options?.sheetName,
    })
      .unwrap()
      .catch((error) => {
        toast.close(selectedFile.name);
        toast({
          title: "Upload Failed",
          status: ToastType.Error,
          duration: 5000,
          description: createElement(
            "div",
            {},
            createElement("div", {}, fileName + " failed to upload"),
            createElement("div", {}, "Error: " + error?.data?.data?.errors[0])
          ),
        });
        console.log(error);
        const errStatus = error?.status;
        let errMsg;
        if (errStatus === 400) {
          errMsg = error?.data?.data?.errors[0];
        } else if (errStatus === 500) {
          errMsg = "Internal Server Error";
        }
        const addErrorToUpload: Partial<IoRecord> = {
          statusMsg:
            errMsg ?? "Network Connection Interrupted or File is missing",
          state: DATA_UPLOAD_STATUS.FAIL,
          hasUploadStarted: errStatus !== 400,
        };
        dispatch(
          updateOnGoingUploads({
            analysisId: params.analysisId!,
            id: ioRecord.id,
            ioRecord: { ...updatedIoRecord, ...addErrorToUpload },
          })
        );
      });
    toast.close(selectedFile.name);
  };

  const handleFileUpload = async ({
    selectedFile,
    fileName,
    source,
    options = {},
  }: {
    selectedFile: File;
    fileName: string;
    source: IDataSource;
    options: IFileExtraOptions;
  }) => {
    try {
      onClose();
      navigate("/analysis/" + params.analysisId + "/data-manager/uploads");
      if (
        getFileExtension(selectedFile.name) === "xlsx" &&
        !options?.sheetName
      ) {
        toast({
          title: "Error: Sheet name is Missing",
          status: ToastType.Error,
        });
        return;
      }

      toast({
        title: fileName + " upload started",
      });
      const ioRecord = await getIoRecord(
        selectedFile,
        fileName,
        source,
        options
      );
      const newIoRecord: IoRecord = {
        ...ioRecord,
        uploadMetaData: {
          file: selectedFile,
          totalSize: selectedFile.size,
        },
      };
      dispatch(
        addOnGoingUploads({
          analysisId: params.analysisId!,
          ioRecord: newIoRecord,
        })
      );

      await handleUploadChunks({
        startByte: 0,
        endByte: 0,
        url: undefined,
        chunkIndex: 0,
        totalSize: selectedFile.size,
        ioRecord,
        selectedFile,
        options: options,
        fileName: fileName,
      });
    } catch (error) {
      const axiosErr = error as AxiosError;
      let description = (axiosErr as any)?.data?.data?.errors[0];
      if (axiosErr.code === "ERR_NETWORK") {
        description = "Please check your internet connection and try again";
      }
      toast({
        title: "Upload Failed",
        description,
        status: ToastType.Error,
      });
    }
  };

  // TODO: Add size vaidation for excel file
  const handleFileChange = (
    event: React.SyntheticEvent<EventTarget>,
    source: IDataSource
  ) => {
    event.preventDefault();

    const target = event.target as HTMLInputElement;
    if (!target.files) return;

    const selectedFile = target.files[0];
    target.value = "";

    if (!validateFileFormat(selectedFile.name, source)) {
      return;
    }

    if (selectedFile.size === 0) {
      toast({
        title: "Validation Error",
        description: "Selected file is Empty. Please select a valid file",
        status: ToastType.Error,
      });
      return;
    }

    if (
      getFileExtension(selectedFile.name) === "xlsx" &&
      selectedFile.size > fileSizeLimit
    ) {
      toast({
        title: "Validation Error",
        description: "File Size greater than 200 MB is not supported for excel",
        status: ToastType.Error,
      });
      return;
    }

    dispatch(
      openModal({
        modalType: ModalTypes.DATASET_NAME,
        modalProps: {
          analysisId: params.analysisId,
          selectedFile,
          source,
        },
      })
    );
  };

  const onClose = () => {
    dispatch(closeModal());
  };

  return {
    handleFileChange,
    onClose,
    handleUploadChunks,
    handleFileUpload,
    validateFile,
    validateTitle,
  };
}
