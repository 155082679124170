// create new slice for analysis
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { AnalysesSchema } from "@/features/ws-manager/types";
import { RootState } from "@/store";

import { IoRecord } from "../types";
// Define a type for the slice state
interface DataManagerState {
  studioAnalysis: Partial<AnalysesSchema> | null | undefined;
  reviewDataset: IoRecord | null;
  ongoingUploads: {
    [key: string]: IoRecord[];
  };
}

// Define the initial state using that type
const initialState: DataManagerState = {
  studioAnalysis: null,
  reviewDataset: null,
  ongoingUploads: {},
};

const dataManagerSlice = createSlice({
  name: "data-manager",
  initialState,
  reducers: {
    setReviewData: (state, action: PayloadAction<IoRecord | null>) => {
      state.reviewDataset = action.payload;
    },
    addOnGoingUploads: (
      state,
      action: PayloadAction<{ analysisId: string; ioRecord: IoRecord }>
    ) => {
      const { analysisId, ioRecord } = action.payload;
      const currentRecords = state.ongoingUploads[analysisId] || [];
      state.ongoingUploads = {
        ...state.ongoingUploads,
        [analysisId]: [...currentRecords, ioRecord],
      };
    },
    removeOnGoingUploads: (
      state,
      action: PayloadAction<{ analysisId: string; ioRecord: IoRecord }>
    ) => {
      const { analysisId, ioRecord } = action.payload;
      state.ongoingUploads = {
        ...state.ongoingUploads,
        [analysisId]: state.ongoingUploads[analysisId]?.filter(
          (upload) => upload.id !== ioRecord.id
        ),
      };
    },
    updateOnGoingUploads: (
      state,
      action: PayloadAction<{
        analysisId: string;
        id: string;
        ioRecord: Partial<IoRecord>;
      }>
    ) => {
      const { analysisId, id } = action.payload;

      state.ongoingUploads = {
        ...state.ongoingUploads,
        [analysisId]: state.ongoingUploads[analysisId].map((upload) => {
          if (upload.id === id) {
            return {
              ...upload,
              ...action.payload.ioRecord,
            };
          }
          return upload;
        }),
      };
    },
    setStudioAnalysis: (
      state,
      action: PayloadAction<Partial<AnalysesSchema> | null>
    ) => {
      state.studioAnalysis = action.payload;
    },
  },
});

export const {
  setReviewData,
  addOnGoingUploads,
  removeOnGoingUploads,
  updateOnGoingUploads,
  setStudioAnalysis,
} = dataManagerSlice.actions;

export const previewIoRecord = (state: RootState) =>
  state.dataManagerPersistReducer.reviewDataset;

export const dataManagerReducer = dataManagerSlice.reducer;

export const OnGoingFileUploads = (state: RootState) =>
  state.dataManagerPersistReducer.ongoingUploads;

export const getOnGoingAnalysisUploads = (
  state: RootState,
  analysisId: string
) => state.dataManagerPersistReducer.ongoingUploads[analysisId];

export const getStudioAnalysis = (state: RootState) =>
  state.dataManagerPersistReducer.studioAnalysis;
