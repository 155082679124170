import {
  Input,
  Textarea,
  Checkbox,
  FormErrorMessage,
  Flex,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { chakraComponents } from "chakra-react-select";
import { isEmpty, PartialObject, pickBy } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AiFillFolder } from "react-icons/ai";
import { MdStore } from "react-icons/md";
import { MdsInfoRound } from "react-icons-with-materialsymbols/mds";
import { useDispatch } from "react-redux";
import { z } from "zod";

import {
  ControlledSelect,
  CreateProjectButton,
  CreateWorkspaceButton,
  customIconComponent,
  LoadingMessage,
  NoOptions,
} from "@/components/controlled-select";
import { SuggestedTags, TagsInput } from "@/components/tag-input";
import { CustomToastProps, useShowToast } from "@/components/toast";
import { TOAST_MESSAGES } from "@/constants/toast-constants.ts";
import { Avatar } from "@/design/components/avatar";
import { Button } from "@/design/components/button";
import { FormControl, FormLabel } from "@/design/components/form";
import { Icon } from "@/design/components/icon";
import { useGetAccessListQuery } from "@/features/user-manager";
import { useCreateWorkflowMutation } from "@/features/workflow-studio";
import {
  AnalysesSchema,
  ProjectCreateSchema,
  ProjectSchema,
  WorkspaceSchema,
} from "@/features/ws-manager/types";
import { useAppSelector } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";
import { Optional } from "@/types";
import { CATEGORY } from "@/utils/enums";
import { cleanData } from "@/utils/object-utils.ts";

import {
  useCreateAnalysesMutation,
  useLazyGetTagsQuery,
  useGetWsItemsByIdListMutation,
  useUpdateAnalysesMutation,
} from "../../api";
import { previewAnalysis, setPreviewAnalysis } from "../../redux";

type AnalysisFormProps = {
  data: Partial<AnalysesSchema> & {
    workspace?: string;
    project?: string;
  };
  parentWs: WorkspaceSchema;
  parentProject: ProjectSchema;
};

const wsOptionsSchema = z.object(
  {
    label: z.optional(z.string()),
    value: z.string({ required_error: "Workspace is required" }),
    icon: z.optional(z.any()),
  },
  {
    required_error: "Workspace is required",
    invalid_type_error: "Workspace is required",
  }
);

const projectOptionsSchema = z.object(
  {
    label: z.optional(z.string()),
    value: z.string(),
    icon: z.optional(z.any()),
  },
  {
    required_error: "Project is required",
    invalid_type_error: "Project is required",
  }
);

type ProjectOptions = z.infer<typeof projectOptionsSchema>;
type WsOptions = z.infer<typeof wsOptionsSchema>;

const AnalysisCreateSchema = z.object({
  workspaceId: wsOptionsSchema,
  project: projectOptionsSchema,
  name: z
    .string({ required_error: "Analysis name is required" })
    .min(1, { message: "Analysis name is required" })
    .regex(/^[a-zA-Z0-9 _ -]*$/, {
      message:
        "Name must only contain English letters, numbers, spaces, underscores, and hyphens",
    })
    .max(50, { message: "Name must be at most 50 characters long" })
    .refine((data) => data.trim() !== "", {
      message: "Analysis name cannot be empty",
    }),
  description: z
    .string()
    .regex(/^[a-zA-Z0-9 _ -]*$/, {
      message:
        "Description must only contain English letters, numbers, spaces, underscores, and hyphens",
    })
    .max(500, { message: "Description must be at most 500 characters long" })
    .optional(),
  tags: z
    .array(z.string())
    .max(5, { message: "You can only add up to 5 tags" }),
  discreet: z.boolean(),
});

type AnalysisFormSchema = z.infer<typeof AnalysisCreateSchema>;

const AnalysisForm = ({
  data: analysisData,
  parentProject,
  parentWs,
}: AnalysisFormProps) => {
  const ref = useRef(null);
  const openAnalysis = useAppSelector(previewAnalysis);
  const toast = useShowToast(undefined, undefined, true);
  const dispatch = useDispatch();
  const { modalProps } = useAppSelector((state) => state.rootReducer.modals);

  const {
    id,
    tags,
    name,
    description,
    workspaceId: workspace,
    projectId: project,
    discreet,
  } = analysisData ?? {};

  const initalSelectedWs = {
    label: "",
    value: workspace ?? parentWs?.id ?? null,
  };

  const [selectedWs, setSelectedWs] = useState<WsOptions | null>(
    initalSelectedWs.value ? initalSelectedWs : null
  );

  const [createAnalysisApi, { isLoading }] = useCreateAnalysesMutation();
  const [updateAnalysisApi, { isLoading: isUpdating }] =
    useUpdateAnalysesMutation();
  const [createWorkflowApi] = useCreateWorkflowMutation();

  const [getTags, { data: tagsData }] = useLazyGetTagsQuery();

  // const { data: wsData, isLoading: isLoadingWs } = useGetWsListQuery();
  const isEditing = analysisData?.id !== undefined;

  const { data: ItemsWithAccess, isLoading: isAccessListLoading } =
    useGetAccessListQuery(
      {
        scope: "analysis",
        permission: isEditing ? "edit" : "create",
      },
      { refetchOnMountOrArgChange: true }
    );

  const [getWsListItems, { data: WsListItems, isLoading: isWsListLoading }] =
    useGetWsItemsByIdListMutation();

  const isLoadingWsList = isWsListLoading || isAccessListLoading;

  const workspaces = WsListItems?.response.data?.workspaces ?? [];
  const projectsWithAccess = ItemsWithAccess?.response.data?.project ?? [];
  const suggestedTags = tagsData?.response.data?.tags ?? [];

  const objectToOptions = (obj: any) => {
    return {
      label: obj.label,
      value: obj.value,
    };
  };

  const wsOptions = useMemo(() => {
    return workspaces.map((ws: WorkspaceSchema) => {
      const icon = ws.icon ? (
        <Avatar size="xs" bgColor={ws.icon} name={ws.name} />
      ) : (
        <Avatar size="xs" bgColor={"brand.300"} name={ws.name} />
        // <Icon as={AiFillFolder} size="md" color="gray.900" />
      );
      return {
        label: ws.name,
        value: ws.id,
        icon: ws.emoji ?? icon,
      };
    }) as WsOptions[];
  }, [workspaces]);

  const projectOptions = useMemo(() => {
    if (!selectedWs || isEmpty(workspaces)) return [];

    let _ws = workspaces.find(
      (ws) => ws.id === (selectedWs as unknown as WsOptions).value
    ) as Partial<WorkspaceSchema> | null;

    if (!isEmpty(workspaces) && !_ws) {
      if (isEditing) {
        toast({
          title: "Cannot Edit",
          description: "Access was revoked or Workspace was deleted.",
          status: "error",
        });
        dispatch(closeModal());
        return [];
      } else {
        _ws = workspaces[0];
      }
    }

    return _ws?.projects
      ?.filter((p) => projectsWithAccess.includes(p.id!))
      .map((proj) => {
        const icon = <Icon as={AiFillFolder} size="md" color="gray.900" />;
        return {
          label: proj.name,
          value: proj.id,
          icon: icon,
        };
      }) as ProjectOptions[];
  }, [selectedWs, workspaces, wsOptions, projectsWithAccess, isEditing]);

  const [defaultValues, defaultProject, defaultWs]: [
    Optional<AnalysisFormSchema, "workspaceId" | "project">,
    ProjectOptions | undefined,
    WsOptions | undefined,
  ] = useMemo(() => {
    const values = {
      name: name ?? "",
      description: description ?? "",
      discreet: discreet ?? false,
      tags: tags?.map((tag) => tag) ?? [],
    };

    let searchWsId: string | undefined, searchProjId: string | undefined;
    if (isEditing) {
      searchWsId = workspace;
      searchProjId = project;
    } else {
      searchWsId = parentWs?.id;
      searchProjId = parentProject?.id;
    }
    const workspaceDetails = wsOptions.find((ws) => ws.value == searchWsId);
    const projectDetails = projectOptions.find(
      (proj) => proj.value == searchProjId
    );

    Object.assign(values, {
      workspaceId: workspaceDetails ? objectToOptions(workspaceDetails) : null,
      project: projectDetails ? objectToOptions(projectDetails) : null,
    });

    return [values, projectDetails, workspaceDetails];
  }, [analysisData, wsOptions, projectOptions]);
  const optionalProjectCheck = isEditing || project;
  const optionalWsCheck = isEditing || workspace;

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isDirty, dirtyFields },
    getValues,
    watch,
    clearErrors,
    setError,
    setValue,
  } = useForm<AnalysisFormSchema>({
    resolver: (function () {
      const schema = AnalysisCreateSchema.extend({
        project: optionalProjectCheck
          ? projectOptionsSchema.optional()
          : projectOptionsSchema.required(),
        workspaceId: optionalWsCheck
          ? wsOptionsSchema.optional()
          : wsOptionsSchema.required(),
      });
      return zodResolver(schema);
    })(),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (isAccessListLoading || !ItemsWithAccess) return;
    getWsListItems({
      entityType: "workspace",
      idList: ItemsWithAccess?.response.data?.workspace ?? [],
    }).catch((e) => console.error(e));
  }, [isAccessListLoading, ItemsWithAccess, getWsListItems]);

  useEffect(() => {
    if (!selectedWs) return;
    getTags({ wsId: selectedWs.value }).catch((e) => console.error(e));
  }, [selectedWs]);

  useEffect(() => {
    if (defaultWs) {
      setValue("workspaceId", defaultWs);
    }
    if (defaultProject) {
      setValue("project", defaultProject);
    }
  }, [defaultProject, defaultWs]);

  const handleFormSubmit: SubmitHandler<AnalysisFormSchema> = (values) => {
    if (isEditing) {
      handleEditFormSubmit();
    } else {
      handleCreateFormSubmit(values);
    }
  };

  const handleEditFormSubmit = async () => {
    if (isDirty) {
      try {
        const sanitizedValues: Partial<ProjectSchema> = Object.keys(
          dirtyFields
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        ).reduce((o, key) => ({ ...o, [key]: getValues(key) }), {});

        const res = await updateAnalysisApi({
          ...sanitizedValues,
          id: id!,
        }).unwrap();
        if (openAnalysis?.id === id) {
          const updatedAnalysis = res.response.data?.analyses?.[0];
          dispatch(setPreviewAnalysis(updatedAnalysis));
        }
        toast({ ...TOAST_MESSAGES.analysisDetailsUpdated });
      } catch (e) {
        console.error("issue", e);
        return;
      }
    }
    dispatch(closeModal());
  };

  const handleCreateFormSubmit: SubmitHandler<AnalysisFormSchema> = async (
    _values: AnalysisFormSchema
  ) => {
    try {
      /// This is to remove Icon key since it contained HTML element causing issues
      const cleanValues = cleanData(_values, ["icon"]);
      console.log(cleanValues);
      const sanitizedValues: PartialObject<ProjectCreateSchema> = pickBy(
        cleanValues,
        (item) => {
          if (typeof item == "string") return item.length > 0;
          if (typeof item == "object") return !isEmpty(item);
          return true;
        }
      );

      await createAnalysis(sanitizedValues as AnalysisFormSchema);
    } catch (e) {
      console.error("issue", e);
    }
  };

  const createAnalysis = async (values: AnalysisFormSchema) => {
    const createData = {
      ...values,
      projectId: values.project.value,
      tags: values.tags?.map((tag) => ({ value: tag })),
    };
    try {
      await createAnalysisApi(createData)
        .unwrap()
        .then((res) => {
          // TODO : Uncomment this when workflow creation is enabled

          const newAnalysis = res.response.data?.analyses?.[0];
          const analysisId = newAnalysis?.id;
          const projId = values.project.value;
          const wkId = values.workspaceId.value;

          // createWorkflowApi({ analysisId: analysisId!! }).catch((e) =>
            // console.log(e));
          
          toast(TOAST_MESSAGES.analysisCreated(values.name));

          if (modalProps.navigate && wkId && projId) {
            dispatch(setPreviewAnalysis(newAnalysis!));
            modalProps.navigate(`/home/${wkId}/project/${projId}`);
          }

          dispatch(closeModal());
        });
    } catch (e) {
      console.error(e);
    }
  };

  const hasSelectedWs = !watch("workspaceId");

  const isSubmitDisabled =
    isLoading ||
    isUpdating ||
    isLoadingWsList ||
    !watch("project") ||
    !watch("workspaceId");

  const validateTags = (text: string) => {
    const englishRegex = /^[A-Za-z0-9_-\s]+$/;
    if (englishRegex.test(text)) {
      return true;
    } else {
      setError("tags", {
        message:
          "Only English letters, numbers, spaces, underscores, and hyphens",
      });
      return false;
    }
  };

  return (
    <form
      /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
      onSubmit={handleSubmit(handleFormSubmit)}
      className="flex items-center justify-between flex-col mt-4 mb-2 gap-4 w-full rounded px-6"
      noValidate={true}
      ref={ref}
    >
      <Flex className="items-start justify-between gap-x-2 w-full">
        <ControlledSelect<AnalysisFormSchema, WsOptions, true>
          name="workspaceId"
          control={control}
          label="Workspace"
          placeholder="Select Workspace"
          placeholderIcon={<Icon as={AiFillFolder} size="md" />}
          options={wsOptions}
          components={{
            ...customIconComponent,
            LoadingIndicator: (props) => (
              <chakraComponents.LoadingIndicator spinnerSize="xs" {...props} />
            ),
            MenuList: (props) => (
              <CreateWorkspaceButton {...props} parent={CATEGORY.Analysis} />
            ),
            NoOptionsMessage: NoOptions,
            LoadingMessage: LoadingMessage,
          }}
          isLoading={isLoadingWsList}
          selectedOptionStyle="check"
          isRequired={true}
          isDisabled={isEditing || !!id || isLoadingWsList}
          onChange={(currentWs) => {
            /** This is a hack to reset value, since normal reset was having issues with the controlled select
             * Even adding onchange made it such that form value was not getting se
             * so had to manually set the value using setValue for current select as well.
             **/
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setValue("project", null);
            setSelectedWs(currentWs as unknown as WsOptions);
            setValue("workspaceId", currentWs as unknown as WsOptions);
          }}
          useBasicStyles
        />

        <ControlledSelect<AnalysisFormSchema, ProjectOptions, true>
          name="project"
          control={control}
          label="Project"
          placeholder="Select Project"
          options={projectOptions}
          isDisabled={isEditing || !!id || isLoadingWsList || hasSelectedWs}
          components={{
            ...customIconComponent,
            MenuList: (props) => (
              <CreateProjectButton
                {...props}
                parent={CATEGORY.Analysis}
                ws={getValues("workspaceId")}
              />
            ),
            NoOptionsMessage: NoOptions,
            LoadingMessage: LoadingMessage,
            LoadingIndicator: (props) => (
              <chakraComponents.LoadingIndicator spinnerSize="xs" {...props} />
            ),
          }}
          placeholderIcon={<Icon as={MdStore} size="md" />}
          isLoading={isLoadingWsList}
          selectedOptionStyle="check"
          isRequired={true}
          useBasicStyles
        />
      </Flex>

      <FormControl
        id="Project-title"
        isRequired={true}
        isInvalid={!!errors.name}
      >
        <FormLabel>Analysis Title</FormLabel>
        <Input {...register("name")} />
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>
      <Flex className="flex-col w-full gap-y-1.5">
        <TagsInput
          setError={setError}
          clearErrors={clearErrors}
          beforeAddValidate={validateTags}
          errorKey="tags"
          addTagOnBlur={true}
          name="tags"
          control={control}
          label="Add Tags"
          isRequired={false}
        />
        {suggestedTags.length > 0 && (
          <SuggestedTags
            tags={suggestedTags.map((tag) => tag.value) ?? []}
            onSelect={(tag) => {
              const newTags = [...new Set([...getValues("tags"), tag])];
              setValue("tags", newTags);
            }}
          />
        )}
        <Flex className="text-gray-700 items-center gap-1 text-sm">
          <MdsInfoRound strokeWidth={22} />
          You can use tags to organise your analyses based on similar categories
        </Flex>
      </Flex>

      <FormControl id="description" isInvalid={!!errors.description}>
        <FormLabel>Description</FormLabel>
        <Textarea {...register("description")} />
        <FormErrorMessage>
          {errors.description && errors.description.message}
        </FormErrorMessage>
      </FormControl>
      {/*
      <FormControl id="isDiscreet" isDisabled={true}>
        <Checkbox {...register("discreet")}>Mark as discreet</Checkbox>
      </FormControl>
      */}
      <Button
        variant="solid"
        colorScheme="secondary"
        type="submit"
        className="pb-0 mt-8 self-end"
        isDisabled={isSubmitDisabled}
        isLoading={isEditing ? isUpdating : isLoading}
      >
        {isEditing ? "Save Details" : "Create Analysis"}
      </Button>
    </form>
  );
};

export default AnalysisForm;
